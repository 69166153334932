<template>
<div class="tag-big-warp">
  <div class="w-1200  tag-big clear bg-f">
    <h3>{{navData.categoryName}}</h3><i></i>
    <ul class="clear" v-if="!self">
      <router-link tag="li" v-for="(item,idx) in navData.children" :key="idx" :to="'/sec?fid='+navData.categoryId+'&amp;cid='+item.categoryId" :class="{active:item.categoryId==$route.query.cid}">{{item.categoryName}}</router-link>
    </ul>
    <ul class="clear" v-else>
      <router-link tag="li" v-for="(item,idx) in navData.children" :key="idx"
                   :to="item.path"
                   :class="{active:item.type==$route.params.type||item.type==activeChildName}">{{item.categoryName}}</router-link>
    </ul>
  </div>
  <div class="tag-big-2 clear bg-f">
    <h3>{{navData.categoryName}}</h3><i></i>
    <h3 class="sec-h3" @click="secClick" :class="{up:sonShow}">{{isNewsDetatil?'返回':'更多业务'}}</h3>
    <ul class="clear" :class="{show:sonShow}" @click.self="sonShow=!sonShow">
      <li v-for="(item,idx) in navData.children" :key="idx" @click="toDetail(item)">
        <p>{{item.categoryName}}</p>
      </li>
    </ul>
  </div>
</div>
</template>

<script>
export default {
  props: {
    navData: { //
      type: Object,
      default:{
				categoryName:'',
				categoryId:'',
				children:[]
			}
    },
		isNewsDetatil:{
			type:Boolean,
			default:false
		},
    self:{
      type:Boolean,
      default:false
    },
    activeChildName:{
      type:String,
      default:''
    }
  },
	data(){
		return{
			sonShow:false
		}
	},
	watch: {
    $route(newVal,oldVal) {
			// console.log('/////////////')
      this.sonShow=false
			// document.body.style.overflow=''
    }
  },
	created(){
		// console.log(this.$route.meta.seNav)
	},
  methods: {
		secClick(){
			if(!this.isNewsDetatil){
				this.sonShow=!this.sonShow
			}else{
				this.$router.go(-1)
				// return false
			}
			// let over=document.body.style.overflow
			// if(over==''||over==null||over=='auto'){
			// 	over='hidden'
			// }else if(over=='hidden'){
			// 	over=='auto'
			// }
			// console.log(over)
		},
		toDetail(item){
			this.sonShow=false
      if(this.self){
        this.$router.push(item.path)
      }else{
        this.$router.push('/sec?fid='+this.navData.categoryId+'&cid='+item.categoryId)
      }

		},
    toItem(item) {
      if (item.seNav=='jishi') {
        this.openLSHDWindow(2018,'11','7004','1','en','http://www.atpworldtour.com/en/atp-challenger-tour')
      }else{
        this.$router.push({path:item.s_link})
			}
    },
    openLSHDWindow(year, wkno, eventid, tour, lang, ref_file, width, height, hasScrollBars) {
      // ADD NAME FIELD and make sure it get's focus!!!
      var theWidth = width;
      var theHeight = height;
      var scrollBars = "scrollbars";
      if (hasScrollBars == false) scrollBars = "scrollbars=0";
      if ((theWidth == "") || (theWidth == null)) theWidth = 1042;
      if ((theHeight == "") || (theHeight == null)) theHeight = 631;
      var theLeft = (screen.availWidth - theWidth) / 2;
      var theTop = (screen.availHeight - theHeight) / 2;
      var strCheckRef = escape(ref_file);

      var lsURL = "http://www.protennislive.com/lshd/main.html?year=" + year + "&wkno=" + wkno + "&eventid=" + eventid + "&tour=" + tour + "&lang=" + lang + "&ref=" + strCheckRef;

      var popupWin = window.open(lsURL, '_' + Math.round(Math.random() * 1000000), 'top=' + theTop + ',left=' + theLeft + ',menubar=0,toolbar=0,location=0,directories=0,status=0,' + scrollBars + ',width=' + theWidth + ', height=' + theHeight);
    }
  }
}
</script>

<style lang="less" scoped>
@import '../assets/css/params.less';
.tag-big-warp{
  border-bottom: 1px solid #cecece;
}
.tag-big {
    padding: 20px 0;
    color: @c2;
    h3 {
        font-size: 16px;
        height: 50px;
        float: left;
        line-height: 50px;
        padding-left: 26px;
        position: relative;
        &::after {
            content: "";
            display: block;
            width: 12px;
            height: 100%;
            position: absolute;
            top: 0;
            left: 8px;
            background-image: url("../assets/img/2021/2021_arrow_1.png");
            background-size: 12px 12px;
            background-repeat: no-repeat;
            background-position: center;
        }
    }
    > i {
        display: inline-block;
        width: 18px;
        height: 50px;
        background-image: url("../assets/img/2021/2021_arrow_2.png");
        margin-left: 11px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 12px 12px;
        float: left;
    }
    ul {
        font-size: 16px;
        display: inline-block;
        line-height: 1;
        float: left;
        padding: 6px 0 6px 10px;
        li {
            margin: 0 20px;
            padding: 10px 0;
            .cus;
          position: relative;
          overflow: hidden;
          &:after{
            content: "";
            display: block;
            width: 100%;
            height: 2px;
            background-color: @main1;
            position: absolute;
            bottom: 1px;
            left: -100%;
            transition: all .3s;
          }
            &:hover {
                color: @c3;
              &:after{
                left: 0;
              }
            }
            &.active {
              &:after{
                left: 0;
              }
              color: @main1!important;
            }
        }
    }
}
.tag-big-2{
	display: none;
}
@media screen and (max-width: 1024px) {
	.tag-big{
		display: none;
	}
	.tag-big-2{
		display: block;
		position: relative;
		padding:0;
		>h3{
			line-height: 48px;
			height:48px;
			background-color: @main1;
			color: #fff;
			border:none;
			border-radius: 0;
			display: block;
			width: 100%;
			margin-left: 0;
			padding:0 22px;
			font-size: 20px;
			.box;
			&:after{
				display: none;
			}
		}
		>i{
			display: none;
		}
		>h3.sec-h3{
			line-height: 40px;
			height:40px;
			background-color: #b4b4b4;
			color: #fff;
			display: block;
			width: 100%;
			padding:0 48px 0 22px;
			font-size: 18px;
			text-align: right;
			.box;
			position: relative;
			&:after{
				content: "";
				display: block;
				width: 18px;
				height:14px;
				background-image: url('../assets/img/2021/down.png');
				background-size: 100% 100%;
				position: absolute;
				left:100%;
				top:50%;
				margin-top: -7px;
				margin-left: -40px;
				transition: all .3s;
				transform-origin: 50% 50%;
			}
			&.up:after{
				transform: rotate(180deg);
			}
		}
		>ul{
			position: absolute;
			top:100%;
			left:0;
			width: 100%;
			height:0;
			transition: all .3s;
			overflow: hidden;
			transform-origin: top center;
      z-index: 10;
			.box;
			&:after{
				width: 100%;
				height:100vh;
				bottom:0;
				left:0;
				position: fixed;
				background-color: rgba(0,0,0,.3);
				display: none;
			}
			&.show{
				height:auto;
				&:after{
					// display: block;
					// z-index: -1;
				}
			}
			li{
				padding: 0 22px;
				background-color: #b4b4b4;
				display: block;
				width: 100%;
				height:38px;
				line-height: 38px;
				border-right: 0;
				color: #fff;
				.box;
				&.active{
					color:#fff!important;
				}
				p{
					font-size: 18px;
					border-top: 1px solid #fff;
				}
			}
		}
	}
}
</style>
