<template>
  <div >
    <tag-big :navData="navData" :self="true"></tag-big>
    <div class="w-1200 clear">
      <div class="wid-25 pt-40">
        <div class="top-img" v-if="topNews" :style="'backgroundImage:url('+(GLOBAL.COMMON)().img+topNews.coverImg+')'"></div>
        <div class="search-box">
          <div class="search-input">
            <el-input placeholder="关键词" v-model="searchValue">
              <el-button slot="append" icon="el-icon-search" @click="init"></el-button>
            </el-input>
          </div>
          <div class="time-list">
            <el-select v-model="time" placeholder="时间范围">
              <el-option v-for="(time,idx) in timeList" :key="idx"
                         :label="time" :value="idx"></el-option>
            </el-select>
          </div>
        </div>
      </div>
      <div class="wid-75" :class="{'pt-40':topNews}">
        <div class="top-news news-text v-center" v-if="topNews">
          <span class="flag">【置顶新闻】</span>
          <router-link tag="div" class="text"
                       :to="'/news_detail?id='+topNews.articleId">
            <h4>{{topNews.title}}</h4>
            <h5>{{topNews.createTime.split(' ')[0]}}</h5>
            <p class="diandian2">{{topNews.summary}}</p>
          </router-link>
        </div>

        <div class="empty-box" v-if="list.length===0">
          <img src="../../assets/img/2021/recruit_empty@2x.png" alt="">
          <p>暂无内容</p>
        </div>

        <ul class="news-list-2021" v-else>

          <router-link tag="li" class="news-text"
                       v-for="(item,idx) in list" :key="idx"
                       :to="'/news_detail?id='+item.articleId"
            v-if="max1000||!topNews||item.articleId!==topNews.articleId">
            <div class="img">
              <div :style="'backgroundImage:url('+(GLOBAL.COMMON)().img+item.coverImg+')'"></div>
            </div>
            <div class="list-text">
              <h4>{{item.title}}</h4>
              <h5>{{item.createTime.split(' ')[0]}}</h5>
              <p class="diandian2">{{item.summary}}</p>
            </div>
            <router-link tag="i" class="link-btn el-icon-arrow-right"
                         :to="'/news_detail?id='+item.articleId"></router-link>
          </router-link>
        </ul>
        <div class="pageDiv" v-if="list.length!=0">
          <el-pagination :page-size="page.pageSize" :current-page.sync="page.pageNum" layout="prev, pager, next"
                         :total="total" @current-change="pageChange"></el-pagination>
        </div>
        <div class="pageDiv-2" v-if="list.length!=0">
          <h5>总共{{Math.ceil(total/page.pageSize)}}页，当前在{{page.pageNum}}/{{Math.ceil(total/page.pageSize)}}页</h5>
          <h5>
            <button @click="pageChange('show')">首页</button>
            <button @click="pageChange('prev')">上一页</button>
            <button @click="pageChange('next')">下一页</button>
            <button @click="pageChange('wei')">尾页</button>
          </h5>
        </div>
        <h4 v-show="list.length>0" class="back-top" @click="toTop"><span>回到顶部</span><em></em></h4>
      </div>
    </div>
  </div>
</template>

<script>
import TagBig from '@/components/tag_big'
import { newsList } from '@/api/index.js'
export default {
  name: 'news-list',
  metaInfo: {
    title: '新闻资讯'
  },
  components: {
    TagBig
  },
  data() {
    return {
      type:0,
      topNews:null,
      searchValue:"",
      time:0,
      timeList:['全部','最近一周','一月之内','一年之内'],
      navData: {
        categoryName: '新闻资讯',
        f_link: '',
        children: [{
          categoryName:"公司新闻",
          path:"/news_list/company",
          type:"company"
        },{
          categoryName:"媒体报导",
          path:"/news_list/business",
          type:"business"
        }]
      },
      page: {
        pageNum: 1,
        pageSize: 6
      },
      total: 0,
      list: [],
      max1000:false
    }
  },
  watch:{
    $route(newVal,oldVal) {
      console.log(newVal)
      let newType=newVal.params.type
      if(newType==='company'||newType==='business'){
        this.type=newType
        this.init()
      }
    },
    time(){
      this.init()
    }
  },
  created() {
    if (this.$route.query.page) {
      this.page.pageNum = parseInt(this.$route.query.page)
    }
    this.type=this.$route.params.type
    this.getData()
  },
  mounted(){
    let bodyWid = document.body.clientWidth || document.body.offsetWidth;
			if(bodyWid<1000){
				this.max1000=true
			}else{
				this.max1000=false
			}
  },
  methods: {
    init(){
      this.page= {
        pageSize: 6,
        pageNum: 1
      }
      this.total= 0
      this.getData()
    },
    toTop() {
      window.scrollTo(0, 0);
    },
    getData() {
      newsList({
        category:this.type==='company'?0:4,
        timeType:this.time-1,
        searchValue:this.searchValue,
        ...this.page}).then(res => {
        this.list = res.rows
        this.total = res.total
        if(this.page.pageNum===1){
          this.topNews=null
        }
        if(this.page.pageNum===1 && res.rows && res.rows.length>0&&res.rows[0].recommendTop==1){
          this.topNews=res.rows[0]
        }
      })
    },
    pageChange(val) {
			if(val=='show'){
				if(this.page.pageNum==1) return false;
				val = 1;
			}
			if(val=='prev'){
				if(this.page.pageNum==1) return false;
				val = this.page.pageNum-1
			}
			if(val=='next'){
				if(this.page.pageNum==Math.ceil(this.total/this.page.pageSize)) return false;
				val = this.page.pageNum+1
			}
			if(val=='wei'){
				if(this.page.pageNum==Math.ceil(this.total/this.page.pageSize)) return false;
				val = Math.ceil(this.total/this.page.pageSize)
			}
      // this.$router.push({
      //   path: '/news_list?page=' + val
      // })
      this.page.pageNum = val
      this.getData()
    }
  }
}
</script>
<style lang="less" scoped>
em,i{
  font-style: normal;
  font-weight: normal;
}
.empty-box{
  img{
    width: 360px;
    margin: 100px auto 30px;
  }
  p{
    text-align: center;
    margin-bottom: 200px;
    color: #6e6e6e;
    font-size: 14px;
  }
}
.search-box{
  margin-top: 77px;
  padding-left: 50px;
  padding-right: 50px;
  .search-input{
    margin-bottom: 22px;
  }
}

.back-top,.pageDiv-2 {
    display: none;
}

.news-text{
  &.v-center{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 90px;
    border-bottom: 1px solid #dcdcdc;
    >div.text{
      height: auto;
      cursor: pointer;
    }
  }

  h4{
    color: #ea5532;
    line-height: 33px;
    font-size: 17px;
  }
  h5{
    font-size: 25px;
    font-weight: 700;
    color: #c8c8c8;
    margin-bottom: 25px;
  }
  p{
    color: #333333;
    line-height: 21px;
    font-size: 14px;
  }
}
.news-list-2021{
  >li{
    padding: 20px 80px 20px 50px;
    border-bottom: 1px solid #dcdcdc;
    display: flex;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    &:hover{
      .link-btn{
        right: 20px;
        opatime: 1;
      }
      &:after{
        left: 0;
      }
    }
    &:after{
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      top: 0;
      left: 100%;
      background-color: #eeeeee;
      position: absolute;
      z-index: -1;
      transition: all .3s;
    }
    .link-btn{
      position: absolute;
      display: block;
      width: 44px;
      height: 44px;
      right: -220px;
      transition: all .3s;
      opatime: 0;
      top: 50%;
      margin-top: -22px;
      background-color: #ea5431;
      color: #fff;
      border-radius: 50%;
      font-size: 38px;
      text-align: center;
      line-height: 44px;

    }
    .img{
      width: 230px;
      height: 150px;
      float: left;
      margin-right: 30px;

      >div{
        height: 100%;
        width: 100%;
        background-color: #EA5532;
        background-size: 100% 100%;
      }
    }
    .list-text{
      flex: 1;
    }
  }
}
.top-img{
  height: 210px;
  background-color: #ea5431;
  background-size: 100% 100%;
  background-position: center;
}
.top-news{
  height: 210px;
  background: #eeeeee;
  padding-left: 60px;
  position: relative;
  .flag{
    position: absolute;
    color: #ea5431;
    font-size: 14px;
    right: 10px;
    top: 10px;
  }
}
@media screen and (max-width: 1024px) {
  .wid-25{
    width: 100%;
    padding-top: 0!important;
    .search-box{
      margin-top: 40px!important;
      padding: 0 20px!important;
      .search-input,.time-list{
        width: 48%;
        float: left;
      }
      .search-input{
        margin-right: 4%;
      }
    }
  }
  .empty-box {
    img {
      width: 180px;
      margin: 0 auto;
    }
    p{
      margin-bottom: 100px;
    }
  }
  .top-img,.top-news{
    display: none!important;
  }
  .wid-75{
    width: 100%!important;
    min-height:calc(100vh - 380px);
    padding: 10px 22px 50px 22px!important;
  }
  .news-list-2021>li{
    padding: 0;
    border: 1px solid #f0f0f0;
    margin-top: 20px;
    background-color: #fff;
    border-radius: 5px;
    display: block;
    .img{
      width: 100%!important;
      height: 184px;
      border-radius: 5px;
      margin-bottom: 10px;

      >div{
        background-size: 100% 100%;
      }
    }
    .list-text{
      padding: 10px 20px;
      h4{
        font-size: 18px;
      }
      h5{
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 10px;
      }
    }
    .link-btn{
      display: none;
    }
  }
  .back-top {
    display: block;
    background-color: #b4b4b4;
    line-height: 48px;
    height: 48px;
    color: #fff;
    text-align: center;
    font-size: 20px;
    position: relative;
    span {
      display: inline-block;
    }
    em {
      display: inline-block;
      width: 18px;
      height: 14px;
      background-image: url("../../assets/img/2021/up.png");
      background-size: 100% 100%;
      margin-left: 7px;
      transition: all 0.3s;
      transform-origin: 50% 50%;
    }
  }
  .pageDiv{
    display: none;
  }
  .pageDiv-2{
    display: block;
    text-align: center;
    color: #000;
    font-size: 12px;
    margin-top: 25px;
    margin-bottom: 15px;
    button{
      display: inline-block;
      background-color: #787878;
      color: #fff;
      padding:5px 8px;
      margin:10px 4px;
      width: 56px;
    }
  }

}
</style>
